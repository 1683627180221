<!-- eslint-disable @angular-eslint/template/prefer-self-closing-tags -->
@if (profilePictureURL()) {
  <p-image
    imageClass="rounded-full object-contain size-full bg-[#f5fbff]"
    tooltipStyleClass="text-sm"
    [alt]="fullName()"
    [pTooltip]="showTooltip() ? fullName() : undefined"
    [src]="profilePictureURL()"
  />
} @else {
  <div
    class="inline-flex size-full min-h-8 min-w-8 items-center justify-center overflow-hidden rounded-full border border-primary-100 bg-primary-50 text-sm"
    tooltipPosition="top"
    tooltipStyleClass="text-sm"
    [pTooltip]="showTooltip() && firstName() ? fullName() : undefined"
  >
    <span class="text-primary">
      <ng-content>
        {{ initials() }}
      </ng-content>
    </span>
  </div>
}
